import { VatType, VatTypeN } from "./vatType";

const VALUTAZIONE_API_ENDPOINT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/valutazione";
const CODES_API_ENDPOINT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/taxmot";
const EVALUATION_QUEUE_RETRIEVE_ENDPOINT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/valutazione/approvation-queue";
const ADD_TO_EVAL_QUEUE_ENDPOINT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/valutazione/add-to-queue";
const UPDATE_EVALUATION_REQUEST_ENDPOINT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/valutazione/update-request";
/*
POST
JSON:

{
    "page": 0
}


// Restituisce 10 valutazioni che l'utente può vedere

 [
    {
        "evaluation_details": {
            "success": {}
        },
        "queue_details": {}
    },
    ...
 ]

*/

type onCodesReady = (mot: string, etax: number, oldMot: string) => void;
type onEvalReady = (resp: JSON) => void;
type onApprovationQueueRetrievalReady = (resp: JSON) => void;
type onAddToEvalQueueReady = (resp: JSON) => void;


function callApi(token: string, mot: string, immatr: string, km: number, plate: string | null, extendedEval: boolean, vin: string | null, vatType?: VatType, notes?: string): Promise<Response> {
    let modifiers: Array<string> = [];
    if (extendedEval) {
        modifiers.push("journal");
    }
    return fetch(VALUTAZIONE_API_ENDPOINT, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            codiceMotornetUnivoco: mot,
            dataImmatricolazione: immatr,
            km,
            plate,
            view: "extended",
            modifiers,
            vin,
            vatType,
            notes,
            sic: true
        })
    });
}

export function addEvaluationToApprovationQueue(token: string, evaluationId: string) {
    fetch(ADD_TO_EVAL_QUEUE_ENDPOINT, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            "id": evaluationId
        })
    })
}

export async function retrieveApprovationQueue(token: string, page: string, onApprovationQueueRetrievalReady: onApprovationQueueRetrievalReady, plate?: string, only_approved?: boolean) {
    if (plate) {
        plate = plate.toUpperCase();
    }
    await fetch(EVALUATION_QUEUE_RETRIEVE_ENDPOINT, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            page,
            plate,
            only_approved
        })
    }).then(r => r.json())
        .then(json => {
            onApprovationQueueRetrievalReady(json)
        })
}

function getCodesInfo(token: string, mot: string, onCodesReady: onCodesReady) {
    fetch(CODES_API_ENDPOINT, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            code: mot,
            kind: "motornet_univoco"
        })
    }).then(r => r.json())
        .then(json => {
            const success = json["success"];
            const etax = success["legacy"] && (success["legacy"]["eurotax"] || "n.d.");
            const oldMot = success["legacy"] && (success["legacy"]["motornetOld"] || "n.d");
            onCodesReady(mot, etax, oldMot)
        })
}

export default function evaluateVehicle(
    token: string,
    mot: string,
    immatr: string,
    km: number,
    plate: string | null,
    extendedEval: boolean,
    onCodesReady: onCodesReady,
    onEvaluationReady: onEvalReady,
    vin: string | null,
    foreign?: { nation: string, immatr: string },
    vatType?: VatType,
    notes?: string
): void {
    getCodesInfo(token, mot, onCodesReady);
    const real_immatr = foreign ? foreign.immatr : immatr;
    callApi(token, mot, real_immatr, km, plate, extendedEval, vin, vatType === VatTypeN.NULL ? undefined : vatType, notes)
        .then((r) => {
            switch (r.status) {
                case 500: {
                    alert("Il veicolo non può essere valutato");
                    throw new Error("server error");
                }
                case 412: {
                    alert("Errore con i parametri specificati per il veicolo. Riprova con parametri diversi.");
                    throw new Error("client error");
                }
                default: {
                    return r.json()
                }
            }
        })
        .then(json => onEvaluationReady(json))
        .catch(e => {
            alert("Non è stato possibile contattare il sito del fornitore");
            //console.log(e);
        });
}

export namespace EvaluationStatus {
    export const IN_QUEUE = "In attesa";
    export const APPROVED = "Approvata";
    export const REJECTED = "Rifiutata";
}

export type EvaluationStatus = typeof EvaluationStatus[keyof typeof EvaluationStatus];

export function updateEvaluationRequest(token: string, payload: { evaluationId: string, price?: number, damages: number, approvationStatus: EvaluationStatus, notes?: string }) {
    fetch(UPDATE_EVALUATION_REQUEST_ENDPOINT, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
}