import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { Accordion, Card, Col, Row, Spinner, Table, Image } from "react-bootstrap";
import { user_has_group } from "../util";
import { addEvaluationToApprovationQueue, retrieveApprovationQueue } from "./components/evaluation";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Placeholder from 'react-bootstrap/Placeholder';
import moment from 'moment';
import { decodeVatTypeN } from "./components/vatType";
const UPDATE_PHOTOS_REQUEST_ENDPOINT = 'https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/valutazione/upload-photo';
const DATE_REGEX = /(\d{4})-(\d{2})-(\d{2})/;

type OutputBoxProps = {
    json?: JSON | null;
    modelInfo?: { brand: string, model: string },
    immatr: string,
    km: number,
    plate?: string,
    codes?: { motUni: string, etax: string, motOld: string },
    foreign?: { immatr: string, nation: string },
    iva?: boolean,
    onIvaChange?: (value: any) => void,
    user: any,
    queueDetails?: JSON,
    photosPayload?: { evaluationId: string, images: Array<any> },
};

interface Journal {
    name: string,
    entries: [string]
}

type OutputBoxState = {
    iva: boolean,
    loadingError: boolean,
    zipUrl: string,
    zipName: string,
    zipCreated: boolean
    limit: boolean,
    previousEvaluations: Array<any>
}

function ImagesGrid({ evaluationId, createImageZip }) {

    const [images, setImages] = useState(new Array());
    const [noImagesFound, setNoImagesFound] = useState(false);
    const fetchingPictures = useRef(false);
    const [showFullscreen, setShowFullscreen] = useState("");
    const [zipUrl, setZipUrl] = useState("");

    useEffect(() => {
        if (fetchingPictures.current) {
            return;
        }
        fetchingPictures.current = true;

        const fetchImages = async () => {
            try {
                const res = await fetch(`https://autobro-public.s3.eu-central-1.amazonaws.com/valutazioni/${evaluationId}/summary.json`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await res.json();
                if (data.length === 0) {
                    setNoImagesFound(true);
                    return;
                }

                let summary = data;
                summary = summary.sort((a, b) => { return parseInt(a.split('.')[0]) - parseInt(b.split('.')[0]) });

                setImages(Array(summary.length).fill([]));

                const tempImages = await Promise.all(
                    summary.map(async (element) => {
                        const imageRes = await fetch(`https://autobro-public.s3.eu-central-1.amazonaws.com/valutazioni/${evaluationId}/${element}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        const blob = await imageRes.blob();
                        const imageURL = URL.createObjectURL(blob);

                        return { bytes: imageURL };

                    })
                );


                setImages(tempImages);
                createImageZip(tempImages, evaluationId);

            } catch (error) {
                console.error('Errore durante il fetch delle immagini:', error);
            }
        }
        fetchImages();

    }, [evaluationId]);

    function handleShowFullscreen(url) {
        setShowFullscreen(url || "");
    }


    return (
        <Row>
            {!noImagesFound && images.length === 0 &&
                <Col className="col-12 mb-3 text-center">
                    <Spinner></Spinner>
                </Col>
            }
            {noImagesFound &&
                <p style={{ backgroundColor: 'red', color: 'white' }}>
                    Nessuna immagine trovata
                </p>
            }
            {images.map((image, index) => (
                <Col className="col-lg-3 mb-3 col-6" key={index}>
                    {image.bytes ? (
                        <Image
                            src={image.bytes}
                            className="single-image-uploader"
                            style={{
                                cursor: 'pointer',
                                width: '100%',
                                backgroundImage: `url(${image.bytes})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                opacity: 1,
                                border: '2px solid #00cb00'
                            }}
                            onClick={() => handleShowFullscreen(image.bytes)}
                        />
                    ) : (
                        <div style={{
                            width: '100%',
                            backgroundColor: '#e0e0e0',
                            border: '2px solid #ccc',
                            opacity: 0.7,
                            height: '118px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Placeholder
                                as="span"
                                bg="primary"
                                animation="wave"
                                style={{ width: '100%', height: '100%' }}
                            />
                        </div>
                    )}
                    <Modal
                        show={showFullscreen !== ""}
                        fullscreen={true}
                        onHide={() => handleShowFullscreen("")}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Immagine</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center' }}>
                            <Image src={showFullscreen} className="img-fluid" />
                        </Modal.Body>
                    </Modal>
                </Col>
            ))}
        </Row>
    );
}



interface SendEvaluationButtonProps {
    userToken: string,
    user: any,
    json: JSON,
    images: Array<any>,
    evaluationId: string,
    queueDetails?: JSON,
    uploadPhoto: (args: { evaluationId: string, bytes: any, name: string, mime: string, user: any, setLoadingError: boolean }) => void
    evaluationSent?: () => void,
    loadingError: boolean
}



function SendEvaluationButton(props: SendEvaluationButtonProps) {
    const [evaluationButtonPressed, setEvaluationButtonPressed] = useState(false);
    const [evaluationSent, setEvaluationSent] = useState(false);
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
    const [progressBarValue, setProgressBarValue] = useState(0);
    const [loadingError, setLoadingError] = useState(false);

    return <>{
        props.queueDetails === undefined &&
        <>
            <p>Siamo pronti a inviare la tua richiesta di valutazione</p>
            <Button disabled={evaluationSent || evaluationButtonPressed}>
                <span className="p-1" onClick={() => {
                    setEvaluationButtonPressed(true);
                    let totalImages = props.images.length;
                    let currentImage = 0;
                    setProgressBarValue(0);
                    (async () => {
                        if (props.images) {
                            for (let index = 0; index < props.images.length; index++) {
                                const image = props.images[index];
                                try {
                                    await props.uploadPhoto({
                                        evaluationId: props.evaluationId,
                                        bytes: image.bytes,
                                        name: `${index}.jpg`,
                                        mime: 'image/jpg',
                                        user: props.user,
                                        setLoadingError: setLoadingError
                                    });
                                    currentImage++;
                                    setProgressBarValue((currentImage / totalImages) * 100);
                                } catch (error) {
                                    console.error(`Upload failed after multiple retries: ${error.message}`);
                                    setEvaluationButtonPressed(false);
                                    setLoadingError(true);
                                    return;
                                }
                            }
                            addEvaluationToApprovationQueue(props.userToken, props.json["rebounce"]["evaluation"]["id"]);
                            setEvaluationSent(true);
                            setEvaluationButtonPressed(false);
                            setShowFeedbackDialog(true);
                            props.evaluationSent && props.evaluationSent();
                        } else {
                            alert('Si è verificato un errore durante l\'invio delle foto. Riprova più tardi.');
                            setEvaluationButtonPressed(false);
                            return;
                        }
                    })();
                }}>Richiedi Approvazione</span>
            </Button>

            {<ProgressBar style={{ "border": loadingError ? "1px solid red" : "" }} variant={loadingError === false ? 'primary' : 'danger'} animated className={'mt-4 custom-progress-bg'} now={progressBarValue} label={`${progressBarValue}%`} />}
            {loadingError &&
                <Card className="mb-3 mt-3 evaluation-deny">
                    <Card.Header>Errore di caricamento</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p>
                                Siamo spiacenti, si sta verificando un problema di connessione durante il caricamento delle foto. Il sistema sta automaticamente tentando di riinviare la richiesta. Ti consigliamo di verificare la connessione internet e di assicurarti di essere connesso a una rete stabile per garantire il completamento del caricamento.
                            </p>
                        </Card.Text>
                    </Card.Body>
                </Card>

            }
        </>
    }
        {
            showFeedbackDialog && <Modal
                size="lg"
                centered
                show={showFeedbackDialog}
                onHide={() => setShowFeedbackDialog(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Richiesta di Valutazione Inviata</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        La tua richiesta di valutazione è stata inviata con successo. Ti verrà inviata una notifica via mail al più presto con il riscontro.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowFeedbackDialog(false)}>Chiudi</Button>
                </Modal.Footer>
            </Modal>
        }
    </>
}

export default class OutputBox extends React.Component<OutputBoxProps, OutputBoxState> {
    state: OutputBoxState = {
        iva: false,
        loadingError: false,
        zipUrl: "",
        zipName: "",
        zipCreated: false,
        limit: false,
        previousEvaluations: [],

    };

    constructor(props: OutputBoxProps) {
        super(props);
        this.buildRebounce = this.buildRebounce.bind(this);
        this.handleIvaChange = this.handleIvaChange.bind(this);
        this.createImageZip = this.createImageZip.bind(this);
        this.state.zipCreated = false;
        this.state.limit = false;
        this.retrievePreviousEvaluations = this.retrievePreviousEvaluations.bind(this);
        if (this.props.json && this.props.json['success'] && this.props.json['success'].rebounce && this.props.json['success'].rebounce.plate) {
            this.retrievePreviousEvaluations(this.props?.json['success'].rebounce.plate);
        }
    }

    handleIvaChange = (event: any) => {
        this.setState({ iva: event.target.checked });
    }

    retrievePreviousEvaluations = async (plate) => {
        let token = this.props.user.getSignInUserSession().getIdToken().getJwtToken();
        try {
            let page = 0;
            while (!this.state.limit) {
                await retrieveApprovationQueue(token, page.toString(), (resp) => {
                    let entries = Object.entries(resp);
                    for (let i = 0; i < entries.length; i++) {
                        let entry = entries[i][1];
                        this.state.previousEvaluations = [...this.state.previousEvaluations, entry];
                    }
                    if (entries.length < 10) {
                        this.state.limit = true;
                    } else {
                        page++;
                    }
                }, plate);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    async createImageZip(images, evaluationId) {
        const JSZip = require('jszip');
        const zip = new JSZip();
        const folder = zip.folder('valutazione ' + this.props.json['success'].rebounce.plate);

        for (const [index, image] of images.entries()) {
            const response = await fetch(image.bytes);
            const blob = await response.blob();
            const filename = `${index}.jpg`;
            folder.file(filename, blob);
        }

        const content = await zip.generateAsync({ type: 'blob' });
        const url = URL.createObjectURL(content);
        this.setState({ zipUrl: url });
        this.setState({ zipName: `valutazione-${this.props.json['success'].rebounce.plate}.zip` });
        this.setState({ zipCreated: true });
    }


    async uploadPhoto({ evaluationId, bytes, name, mime, user, setLoadingError }) {

        if (evaluationId === undefined) {
            return;
        }

        let attempt = 0;
        const uploadWithRetry = async () => {
            attempt++;
            try {
                const response = await fetch(UPDATE_PHOTOS_REQUEST_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${user.getSignInUserSession().getIdToken().getJwtToken()}`,
                        "Content-Type": "application/json",
                        "Content-Length": `${bytes.length}`
                    },
                    body: JSON.stringify({
                        evaluationId,
                        bytes,
                        name,
                        mime
                    })
                });

                if (!response.ok) {
                    throw new Error(`Upload failed with status ${response.status}`);
                }
                setLoadingError(false);
                return "Success";
            } catch (error) {
                const delays = [2000, 4000, 8000]
                const fixed_delay = 8000;
                const delay = attempt <= 3 ? delays[attempt - 1] : fixed_delay;
                console.error(`Upload attempt ${attempt} failed: ${error.message}. Retrying in ${delay}ms...`);
                await new Promise(resolve => setTimeout(resolve, delay));
                setLoadingError(true);
                return await uploadWithRetry();
            }
        };

        return await uploadWithRetry();
    }




    buildRebounce(): React.ReactNode {
        const immatr_raw = (this.props.json && this.props.json["success"] && this.props.json["success"]["rebounce"] && this.props.json["success"]["rebounce"]["registrationDate"]) || this.props.immatr;
        const immatr = DATE_REGEX.exec(immatr_raw);
        const foreignImmatr = this.props.foreign && DATE_REGEX.exec(this.props.foreign.immatr);
        const foreignNation = (this.props.foreign && ` in ${this.props.foreign.nation}`) || " (provenienza sconosciuta)";
        const s1 = this.props.modelInfo ? ` una ${this.props.modelInfo.brand} ${this.props.modelInfo.model}` : " il veicolo";
        const s2 = (this.props.json && this.props.json["success"] && this.props.json["success"]["rebounce"] && this.props.json["success"]["rebounce"]["plate"] && ` targato ${this.props.json["success"]["rebounce"]["plate"] || `N.D.`}`) || (this.props.plate ? `(${this.props.plate.toLocaleUpperCase()})` : "");
        const s3 = this.props.foreign ? " in Italia" : "";
        const km = (this.props.json && this.props.json["success"] && this.props.json["success"]["rebounce"] && this.props.json["success"]["rebounce"]["km"]) || this.props.km;
        const scomm = (this.props.json && this.props.json["success"] && this.props.json["success"]["codici"] && this.props.json["success"]["codici"]["isCommercial"] && " commerciale") || "";
        let failure = true;
        let failureMessage = null;
        try {
            failure = this.props.json ? ((this.props.json["success"] && this.props.json["success"]["autobro"] && this.props.json["success"]["autobro"]["evaluation"] == null) || (this.props.json["success"] && this.props.json["success"]["laCompriamoNoi"] && this.props.json["success"]["laCompriamoNoi"]["evaluation"] == null)) : false;
            failureMessage = this.props.json ? this.props.json["success"]["autobro"]["report"]["value"].join(<br />) : <p></p>;
        } catch { };
        return <div className="valutazione-bounce">
            {immatr &&
                <p className="rebounce">
                    {(failure && "Non abbiamo") || "Abbiamo"} valutato{s1}{scomm}{s2}<br />
                    immatr. il {immatr[3]}/{immatr[2]}/{immatr[1]}{s3}<br />
                    con {km} km
                </p>
            }
            {this.props.foreign && foreignImmatr &&
                <p className="estero-info">
                    Prima immatr. il {foreignImmatr[3]}/{foreignImmatr[2]}/{foreignImmatr[1]}{foreignNation}
                </p>
            }
            {failure && failureMessage}
        </div>
    }


    renderSuccess(json: JSON): React.ReactNode {
        // Valore di ritiro
        let withdrawal: string;
        let lcnValue: number = 0;
        const userToken: string = this.props.user.getSignInUserSession().getIdToken().getJwtToken();

        try {
            lcnValue = json["laCompriamoNoi"]["evaluation"]["value"];
            if (this.state.iva ? this.state.iva : false) {
                const d = lcnValue * 0.22 * 0.4;
                lcnValue -= d;
                lcnValue = Math.round(lcnValue);
            }
            withdrawal = `€ ${lcnValue}`
        } catch {
            withdrawal = "N.D.";
        }

        // Valore di vendita
        let autobro: string;
        let autobroValue: number = 0;
        try {
            autobro = `€ ${json["autobro"]["evaluation"]["value"]} `
            autobroValue = json["autobro"]["evaluation"]["value"];
        } catch {
            autobro = "N.D.";
        }

        // Affidabilità
        let reliability = null;
        try {
            reliability = (json["laCompriamoNoi"] && json["laCompriamoNoi"]["reliability"] && json["laCompriamoNoi"]["reliability"]["value"]);
        } catch { }

        // Autobro Report
        let autobroReport;
        try {
            autobroReport = <><dt>Report Autobro</dt><dd>{json["autobro"]["report"]["value"].join(<br />) || '-'}</dd></>;
        } catch { }

        let laCompriamoNoiReport;
        try {
            laCompriamoNoiReport = <><dt>Report LaCompriamoNoi</dt><dd>{json["laCompriamoNoi"]["report"]["value"].join('\n') || '-'}</dd></>
        } catch { }

        let journals: Array<Journal> = [];
        try {
            json["autobro"]["journal"].forEach(element => {
                journals.push(element)
            });
            json["laCompriamoNoi"]["journal"].forEach((element) => {
                if (element.name === "withdrawal" || element.name === "LaCompriamoNoi") {
                    journals.push(element);
                }
            });
        } catch { }

        let morganaEvaluation = null;
        let morganaReliability = null;
        try {
            json["evaluations"].forEach((e) => {
                if (e["id"] === "morgana:1") {
                    morganaEvaluation = (e["value"] && e["value"]["evaluation"] && `€ ${e["value"]["evaluation"]["value"]}`);
                    morganaReliability = (e["value"] && e["value"]["reliability"] && `${(e["value"]["reliability"]["value"] * 100).toFixed(0)}`);
                    e["value"]["journal"].forEach((t) => journals.push(t))
                }
            });
        } catch { }

        // Parse rebounced registration date
        const immatr = DATE_REGEX.exec((json["rebounce"] && json["rebounce"]["registrationDate"]) || this.props.immatr);

        let isSeller = false;
        let businessCosts = 0;
        let eurotaxBlu = "N.D.";
        let eurotaxGiallo = "N.D.";
        let isCommercial = false;
        try {
            isCommercial = json["codici"]["isCommercial"];
        } catch { }

        try {
            isSeller = this.props.user.signInUserSession.accessToken.payload["cognito:groups"].includes('Venditori');
            businessCosts = Object.keys(json["sellerCosts"]).map((x) => json["sellerCosts"][x]).reduce((acc, v) => acc + v) + lcnValue;
        } catch { }

        let sellsAvg = null;
        let sellsTot = null;
        try {
            sellsAvg = json["vendite"]["avg"]["value"];
            sellsTot = json["vendite"]["tot"]["value"];
        } catch { }

        let evaluationId = this.props?.evaluationId;
        let images = this.props.photos?.images;
        let notes = this.props?.json.success.rebounce.evaluation.notes;

        const hasAdvancedRequests =
            (user_has_group(this.props.user, "RichiesteAvanzate") && (this.props.queueDetails && this.props.queueDetails.stato === "Approvata"))
            || (!user_has_group(this.props.user, "RichiesteAvanzate"));

        return <>
            {this.props.queueDetails !== undefined && <ImagesGrid evaluationId={evaluationId} createImageZip={this.createImageZip} />}
            {this.props.queueDetails !== undefined && notes &&
                <Row className="mb-3">
                    <h5>Note Aggiuntive</h5>
                    <Col>
                        {notes}
                    </Col>
                </Row>
            }
            {this.props.queueDetails !== undefined && this.props.queueDetails.stato !== "In attesa" && this.props.json && this.props.json.success && this.props.json.success.rebounce && this.props.json.success.rebounce.vatType &&
                <Row>
                    <Col>
                        <FloatingLabel className="mb-3" label="Tipo IVA">
                            <Form.Control
                                disabled={true}
                                value={decodeVatTypeN(this.props.json.success.rebounce.vatType)}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
            }
            <div className="valutazione-box" >
                <div className="valutazione-header" >
                    {!user_has_group(this.props.user, "RichiesteAvanzate") && !this.props.queueDetails &&
                        <Row>
                            <Col className="post-price-form">
                                <Form.Group className="iva-box" controlId="acquisto-con-iva">
                                    <Form.Check
                                        type="switch"
                                        id={`acquisto-con-iva-switch`}
                                        label={`Acquisto da P.IVA`}
                                        onChange={this.handleIvaChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    {hasAdvancedRequests && <Row xs={1} md={2} className="g-2 justify-content-center">
                        {!isSeller &&
                            <Col>
                                <Card style={!user_has_group(this.props.user, "Limited") ? { backgroundImage: `url("autobro_symbol.png")` } : {}}>
                                    <Card.Header><Card.Title>{!user_has_group(this.props.user, "Limited") ? `Autobro` : `Valore Vendita`}</Card.Title></Card.Header>
                                    <Card.Body>
                                        <Card.Subtitle>{!user_has_group(this.props.user, "Limited") ? `Valore di Vendita` : `Parametrizzato al Veicolo`}</Card.Subtitle>
                                        <Card.Text>{autobro}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }
                        {user_has_group(this.props.user, "Limited") ||
                            <Col>
                                <Card style={{ backgroundImage: user_has_group(this.props.user, "SoloVendita") ? `` : `url("lacompriamonoi_symbol.png")` }}>
                                    <Card.Header><Card.Title>{user_has_group(this.props.user, "SoloVendita") ? `Massimo Valore di Ritiro` : `LaCompriamoNoi`}</Card.Title></Card.Header>
                                    <Card.Body>
                                        <Card.Subtitle>{!user_has_group(this.props.user, "SoloVendita") && `Massimo Valore di Ritiro`}</Card.Subtitle>
                                        <Card.Text>{withdrawal}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }
                        {user_has_group(this.props.user, "Limited") &&
                            <Col>
                                <Card>
                                    <Card.Header><Card.Title>Media Vendite</Card.Title></Card.Header>
                                    <Card.Body>
                                        {sellsTot && sellsTot > 0 && <Card.Subtitle>Su {`${sellsTot}`} veicol{sellsTot === 1 ? 'o' : 'i'} present{sellsTot === 1 ? 'e' : 'i'}</Card.Subtitle>}
                                        <Card.Text>{sellsAvg ? `€ ${sellsAvg.toFixed(0)}` : "N.D."}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }
                        {!user_has_group(this.props.user, "SoloVendita") && isSeller &&
                            <Col>
                                <Card>
                                    <Card.Header><Card.Title>Costo Azienda</Card.Title></Card.Header>
                                    <Card.Body>
                                        <Card.Subtitle>Totale Costo Azienda</Card.Subtitle>
                                        <Card.Text>{`€ ${businessCosts}`}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }
                    </Row>}
                </div>
                {hasAdvancedRequests && reliability !== null &&
                    <div className="affidabilita" style={{
                        background: `linear-gradient(90deg, #0d6efd2a 0%, #0d6efd2a ${reliability * 100}%, rgba(255, 255, 255, 1) ${reliability * 100}%, rgba(255, 255, 255, 1) 100%)`
                    }}>
                        Affidabilità:
                        <strong> {`${isCommercial ? "Veicolo Commerciale" : (reliability * 100).toFixed() + '%' || "N.D."} `}</strong>
                    </div>
                }
                {hasAdvancedRequests &&
                    user_has_group(this.props.user, "Venditori") && !user_has_group(this.props.user, "SoloVendita") && json["sellerCosts"] && json &&
                    <div className="seller-infos">
                        <h3>Costi Accessori</h3>
                        <Table>
                            <tbody>
                                {Object.keys(json["sellerCosts"]).map((x) => <tr><td>{`${x.charAt(0).toUpperCase()}${x.slice(1)}`}</td><td>{`€ ${json["sellerCosts"][x]}`}</td></tr>)}
                                <tr><td><b>Totale Costo Azienda</b></td><td><b>{`€ ${Object.keys(json["sellerCosts"]).map((x) => json["sellerCosts"][x]).reduce((acc, v) => acc + v) + lcnValue}`}</b></td></tr>
                            </tbody>
                        </Table>
                    </div>
                }
                {hasAdvancedRequests && this.buildRebounce()}
                {hasAdvancedRequests && <Button className="print-button m-3" variant="secondary" onClick={() => {
                    const accordion = document.querySelector(".accordion-collapse");
                    const navbar = document.querySelector("nav.navbar");
                    const printButton = document.querySelector("button.print-button");
                    accordion?.setAttribute("style", "display: block !important");
                    navbar?.setAttribute("style", "display: none");
                    printButton?.setAttribute("style", "display: none");
                    window.print();
                    accordion?.removeAttribute("style");
                    navbar?.removeAttribute("style");
                    printButton?.removeAttribute("style");
                }}>
                    <img src="printer.svg" alt="stampa" />
                    <span className="p-1">Stampa la tua Valutazione</span>

                </Button>}

                {
                    user_has_group(this.props.user, "BackOfficeRA") && this.props.queueDetails !== undefined &&
                    <Button id="download-zip" disabled={this.state.zipCreated ? false : true} onClick={() => {
                        const downloadLink = document.createElement('a');
                        downloadLink.href = this.state.zipUrl;
                        downloadLink.download = `${this.state.zipName}`;
                        downloadLink.click();
                    }}>
                        <img src="photo-downloader.svg" alt="download foto" />
                        <span className="p-1">Scarica tutte le foto</span>
                    </Button>
                }

                {user_has_group(this.props.user, "ApprovazioneRichieste") && this.props.queueDetails !== undefined && this.props.queueDetails.stato == 'In attesa' &&
                    <div className="previous-evaluations">
                        {this.state.previousEvaluations.length > 0 &&
                            <>
                                <h3 className="text-center">Valutazioni Precedenti</h3>
                                <Row>
                                    {this.state.previousEvaluations.filter((e) => {
                                        if (e.queue_details.stato != 'In attesa') {
                                            return e;
                                        }
                                    }).map((e) => {
                                        if (e['evaluations_details']) {
                                            let evaluation_details = e['evaluations_details'];
                                            let queue_details = e['queue_details'];
                                            let lcn = queue_details?.prezzo_confermato || (evaluation_details && evaluation_details.laCompriamoNoi && evaluation_details.laCompriamoNoi.evaluation && evaluation_details.laCompriamoNoi.evaluation.value);
                                            let eval_dmg = queue_details && Math.floor(parseInt(queue_details.stima_danni) / 10) * 10 || "-";
                                            let eval_maxval = queue_details && Math.floor(parseInt(queue_details.prezzo_confermato) / 10) * 10 || "-";
                                            let eval_val = typeof eval_dmg === "number" && typeof eval_maxval === "number" && eval_dmg + eval_maxval || "-";
                                            let autobro_val = evaluation_details && evaluation_details.autobro && evaluation_details.autobro.evaluation ? evaluation_details.autobro.evaluation.value : '-';
                                            let marca = evaluation_details && evaluation_details.vehicleDesc && evaluation_details.vehicleDesc.marca ? evaluation_details.vehicleDesc.marca : "-";
                                            let model = evaluation_details && evaluation_details.vehicleDesc && evaluation_details.vehicleDesc.modello ? evaluation_details.vehicleDesc.modello : "-";
                                            let version = evaluation_details && evaluation_details.vehicleDesc && evaluation_details.vehicleDesc.version ? evaluation_details.vehicleDesc.version : "-";
                                            let time = new Date(evaluation_details?.rebounce.evaluation.time).toLocaleDateString();
                                            let evaluation_status = queue_details.stato;
                                            let seller = queue_details.user ? queue_details.user : "Sconosciuto";
                                            let approvatore = queue_details.approvata_da ? queue_details.approvata_da : "Sconosciuto";
                                            let notes = queue_details.note ? queue_details.note : "Nessuna nota";
                                            let card_class = queue_details.stato === "Approvata" ? "evaluation-approved" : "evaluation-deny";
                                            return <Col className="col-lg-4 mb-3">
                                                <Card className={`${card_class}`}>
                                                    <Card.Header>
                                                        <Row>
                                                            <Col className="col-12 fw-bold">
                                                                {time}
                                                            </Col>
                                                            <Col className="col-12">
                                                                {approvatore}
                                                            </Col>
                                                            <Col className="col-12">
                                                                {seller}
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            {
                                                                queue_details.stato !== "Approvata" ? <Col className="col-12">
                                                                    Rifiutata
                                                                </Col> :
                                                                    <>
                                                                        <Col className="col-6">
                                                                            <Row>
                                                                                <Col className="col-12">
                                                                                    Base
                                                                                </Col>
                                                                                <Col className="col-12">
                                                                                    {eval_val}
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col className="col-6">
                                                                            <Row>
                                                                                <Col className="col-12">
                                                                                    Danni
                                                                                </Col>
                                                                                <Col className="col-12">
                                                                                    {eval_dmg}
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col className="col-12 fw-bold">
                                                                            Valore Atto
                                                                        </Col>
                                                                        <Col className="col-12 fw-bold">
                                                                            {eval_maxval}
                                                                        </Col>
                                                                    </>
                                                            }

                                                        </Row>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <Row>
                                                            <Col className="col-12">
                                                                Note
                                                            </Col>
                                                            <Col className="col-12">
                                                                {notes}
                                                            </Col>
                                                        </Row>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>

                                        }
                                        return '';
                                    })
                                    }
                                </Row>
                            </>
                        }
                    </div>
                }


                {
                    user_has_group(this.props.user, "RichiesteAvanzate") && this.props.queueDetails === undefined && <SendEvaluationButton
                        userToken={userToken}
                        json={json}
                        images={images}
                        evaluationId={evaluationId}
                        queueDetails={this.props.queueDetails}
                        uploadPhoto={this.uploadPhoto}
                        evaluationSent={() => { }}
                        user={this.props.user}
                        loadingError={this.state.loadingError}
                    />
                }
                {
                    !user_has_group(this.props.user, "Venditori") && !user_has_group(this.props.user, "Limited") && !user_has_group(this.props.user, "RichiesteAvanzate") &&
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Mostra di più</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        {json["mediaIntergea"] !== null &&
                                            <tr><td>
                                                {json["mediaIntergea"]["message"]}
                                            </td>
                                                <td>
                                                    {json["mediaIntergea"]["value"] !== null
                                                        ? <>
                                                            <p>€ {json["mediaIntergea"]["value"]["mean"].toFixed(0)}<br />
                                                                ({json["mediaIntergea"]["value"]["tot"]} veicol{json["mediaIntergea"]["value"]["tot"] === 1 ? 'o' : 'i'})</p>
                                                        </>
                                                        : "n.d."}
                                                </td>
                                            </tr>
                                        }
                                        {json["streetpriceMin"] !== null &&
                                            <tr>
                                                <td>{json["streetpriceMin"]["message"]}</td>
                                                <td>{`€ ${json["streetpriceMin"]["value"]}`}</td>
                                            </tr>
                                        }
                                        {json["stockMean"] &&
                                            <tr>
                                                <td>{json["stockMean"]["message"]}</td>
                                                <td>{json["stockMean"]["value"] || "n.d"}</td>
                                            </tr>
                                        }
                                        {json["eurotax"] !== null && json["eurotax"]["nonKmBlu"] &&
                                            <tr>
                                                <td>{json["eurotax"]["nonKmBlu"]["message"]}</td>
                                                <td>{json["eurotax"]["nonKmBlu"]["value"]}</td>
                                            </tr>
                                        }
                                        {json["eurotax"] !== null && json["eurotax"]["blu"] &&
                                            <tr>
                                                <td>{json["eurotax"]["blu"]["message"]}</td>
                                                <td>{json["eurotax"]["blu"]["value"]}</td>
                                            </tr>
                                        }
                                        {json["eurotax"] !== null && json["eurotax"]["giallo"] &&
                                            <tr>
                                                <td>{json["eurotax"]["giallo"]["message"]}</td>
                                                <td>{json["eurotax"]["giallo"]["value"]}</td>
                                            </tr>
                                        }
                                        {this.props.user && this.props.user.signInUserSession.accessToken.payload["cognito:groups"] && this.props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("Journal") &&
                                            <tr>
                                                <td>Valutazione Estesa</td>
                                                <td>{`${morganaEvaluation || "N.D."} ${`(Aff. ${morganaReliability || "-"}%)` || ""}`}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                                {autobroReport}
                                {laCompriamoNoiReport}
                                <ul className="journal-container">
                                    {journals.map((e) => {
                                        return (<li><span className="journal-title">{e.name}</span><ul className="journal-entries">
                                            {e.entries.map((x) => {
                                                return <li>{x}</li>
                                            })}
                                        </ul></li>);
                                    })}
                                </ul>

                                {
                                    this.props?.json.success?.sic && (user_has_group(this.props.user, "Autorizzati")) &&

                                    <div className="storico-sinistri mb-3">
                                        <>
                                            <dt>Storico Sinistri</dt>
                                            <dd>
                                                {this.props?.json?.success?.sic?.registeredAccidents?.length > 0 ? (
                                                    <>
                                                        <p className="mb-0 text-center">Sono stati registrati sinistri negli anni:</p>
                                                        <p className="text-center">
                                                            {this.props.json.success.sic.registeredAccidents.map((sinistro, index, array) => (
                                                                <span key={index}>
                                                                    {sinistro['year']}
                                                                    {index < array.length - 1 && ', '}
                                                                </span>
                                                            ))}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <p>Nessun sinistro trovato</p>
                                                )}
                                            </dd>
                                        </>
                                    </div>


                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                }
            </div >
        </>
    }

    renderError(json: JSON): React.ReactNode {
        return <div className="valutazione-box"><h1><u>Non</u> Valutabile</h1><p>Il fornitore non ha restituito i dati per il veicolo richiesto</p></div>
    }

    renderRouter(json: JSON): React.ReactNode {
        switch (Object.keys(json)[0]) {
            case "success": return this.renderSuccess(json["success"]);
            case "error": return this.renderError(json["error"]);
            default: return (<h1>Error</h1>)
        }
    }



    render(): React.ReactNode {
        let json = this.props.json;

        var _ = require('lodash');

        // If we have a queueDetails object with a valid prezzo_confermato field, we
        // show it instead of json.success.LaCompriamoNoi.Evaluation.value
        if (this.props.queueDetails && this.props.queueDetails.prezzo_confermato && !Number.isNaN(parseInt(this.props.queueDetails.prezzo_confermato))) {
            // Substitute the value in the JSON object, we need to generate each step if it's not present
            _.mergeWith(json, { "success": { "laCompriamoNoi": { "evaluation": { "value": Math.floor((parseInt(this.props.queueDetails.prezzo_confermato) / 10)) * 10 }, "reliability": { "value": 1 } } } });
        }


        return (
            <div className="valutazione-box" >
                {
                    this.props.codes &&
                    <div className="codes-box">
                        <Row>
                            <Col>Motornet Univoco</Col>
                            <Col>Eurotax</Col>
                            <Col>Motornet (Legacy)</Col>
                        </Row>
                        <Row>
                            <Col>{this.props.codes.motUni}</Col>
                            <Col>{this.props.codes.etax}</Col>
                            <Col>{this.props.codes.motOld}</Col>
                        </Row>
                    </div>
                }
                {json ? this.renderRouter(json) : <Spinner variant="primary" className="m-3"></Spinner>}
            </div >
        )
    }
}